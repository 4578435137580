import React from "react";
import theme from "theme";
import { Theme, Text, Image, Button, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | RenovateRight
			</title>
			<meta name={"description"} content={"Трансформуємо простір, покращуємо життя"} />
			<meta property={"og:title"} content={"Головна | RenovateRight"} />
			<meta property={"og:description"} content={"Трансформуємо простір, покращуємо життя"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66f139f428b5e30023acb4a0/images/3391333.png?v=2024-09-23T15:22:04.573Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66f139f428b5e30023acb4a0/images/3391333.png?v=2024-09-23T15:22:04.573Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66f139f428b5e30023acb4a0/images/3391333.png?v=2024-09-23T15:22:04.573Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66f139f428b5e30023acb4a0/images/3391333.png?v=2024-09-23T15:22:04.573Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66f139f428b5e30023acb4a0/images/3391333.png?v=2024-09-23T15:22:04.573Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66f139f428b5e30023acb4a0/images/3391333.png?v=2024-09-23T15:22:04.573Z"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.Header>
			<Override slot="quarklycommunityKitMenu" />
		</Components.Header>
		<Section padding="60px 0 70px 0" background="linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(255, 255, 255, 0.5) 100%),rgba(0, 0, 0, 0) url(https://cempautflit.com/info/bg.jpg) 0% 0% /cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Hero-9">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 700 64px/1.2 --fontFamily-sansTrebuchet"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--darkL1"
					lg-padding="0px 0 0px 0"
				>
					FocusClub
				</Text>
				<Image
					src="https://uploads.quarkly.io/66f139f428b5e30023acb4a0/images/1-1.jpg?v=2024-09-23T15:34:50.673Z"
					display="block"
					width="100%"
					object-fit="cover"
					margin="0px 0px 25px 0px"
					border-radius="15px"
					min-height="300px"
					srcSet="https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/1-1.jpg?v=2024-09-23T15%3A34%3A50.673Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/1-1.jpg?v=2024-09-23T15%3A34%3A50.673Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/1-1.jpg?v=2024-09-23T15%3A34%3A50.673Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/1-1.jpg?v=2024-09-23T15%3A34%3A50.673Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/1-1.jpg?v=2024-09-23T15%3A34%3A50.673Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/1-1.jpg?v=2024-09-23T15%3A34%3A50.673Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/1-1.jpg?v=2024-09-23T15%3A34%3A50.673Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="#50555a"
					padding="0px 60px 0px 60px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					Ласкаво просимо до Focus Club, де ваша подорож у цифрову сферу летить. У FocusClub ми більше, ніж просто агентство цифрового маркетингу – ми архітектори вашої історії успіху в Інтернеті. Наша відданість стратегічним інноваціям, спеціалізованим кампаніям і відчутному впливу виділяє нас серед цифрового маркетингу, що постійно розвивається. Приєднуйтесь до нас, коли ми досліджуємо суть FocusClub і вирушаємо в трансформаційну подорож, щоб підвищити присутність вашого бренду в цифровій сфері.
				</Text>
				<Button
					margin="0px 15px 0px 0px"
					padding="12px 28px 12px 28px"
					background="--color-green"
					border-radius="8px"
					font="normal 400 17px/1.5 --fontFamily-sans"
					sm-margin="0px 0 15px 0px"
					transition="background-color 0.2s ease 0s"
					border-width="2px"
					border-style="solid"
					border-color="--color-green"
					hover-background="rgba(63, 36, 216, 0)"
					hover-color="--darkL1"
					href="/contact-us"
					type="link"
					text-decoration-line="initial"
				>
					Звʼязатися з нами
				</Button>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box
				padding="80px 72px 80px 72px"
				background="rgba(214, 255, 213, 0.7)"
				border-radius="24px"
				margin="0px 0px 64px 0px"
				sm-padding="60px 36px 60px 36px"
			>
				<Box
					width="100%"
					display="flex"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--dark"
						text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Відкрийте для себе суть FocusClub
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--greyD3" md-text-align="center">
						У FocusClub ми плетемо гобелен із цифрових історій успіху. Завдяки відданості стратегіям, орієнтованим на результат, і глибокому розумінню цифрового ландшафту, що постійно розвивається, ми не просто маркетингова агенція – ми ваші партнери в навігації в динамічному світі онлайн-присутності.
					</Text>
				</Box>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 4%"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
			>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						• Стратегічне розуміння
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						наша команда досвідчених професіоналів має багатий досвід, гарантуючи, що ваші цифрові стратегії є не лише актуальними, а й передовими.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 30px 0px">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						• Індивідуальні кампанії:{" "}
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						тут немає універсальних рішень. Ми адаптуємо наші підходи відповідно до ваших унікальних цілей, посилюючи голос вашого бренду та охоплюючи потрібну аудиторію.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						• Вимірний вплив:{" "}
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						наша увага до аналітики та прийняття рішень на основі даних гарантує, що ваші маркетингові інвестиції перетворюються на вимірювані, відчутні результати.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			sm-padding="40px 0 40px 0"
			min-height="400px"
			sm-min-height="auto"
			display="flex"
			flex-direction="column"
		>
			<Override slot="SectionContent" flex-direction="row" gap="30px" flex-wrap="wrap" />
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="50%"
				md-width="100%"
				md-margin="16px 0px 0px 0px"
				sm-margin="16px 0px 32px 0px"
			>
				• Технічно підковані рішення: хоча ми тримаємося подалі від технічних модних слів, наш закулісний технічно підкований підхід гарантує, що ваші кампанії використовують найновіші технології, не втрачаючи зв’язку з аудиторією.
			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="50%"
				md-width="100%"
				md-margin="16px 0px 0px 0px"
				sm-margin="16px 0px 32px 0px"
			>
				• Творчий блиск: залучайте свою аудиторію візуально вражаючим і вражаючим вмістом, створеним, щоб захопити та конвертувати.
			</Text>
			<Box
				align-self="center"
				display="flex"
				width="100%"
				justify-content="space-around"
				margin="25px 0px 0px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/66f139f428b5e30023acb4a0/images/1-2.jpg?v=2024-09-23T15:34:50.682Z"
					display="block"
					width="45%"
					srcSet="https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/1-2.jpg?v=2024-09-23T15%3A34%3A50.682Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/1-2.jpg?v=2024-09-23T15%3A34%3A50.682Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/1-2.jpg?v=2024-09-23T15%3A34%3A50.682Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/1-2.jpg?v=2024-09-23T15%3A34%3A50.682Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/1-2.jpg?v=2024-09-23T15%3A34%3A50.682Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/1-2.jpg?v=2024-09-23T15%3A34%3A50.682Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/1-2.jpg?v=2024-09-23T15%3A34%3A50.682Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Image
					src="https://uploads.quarkly.io/66f139f428b5e30023acb4a0/images/1-3.jpg?v=2024-09-23T15:34:50.680Z"
					display="block"
					width="45%"
					srcSet="https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/1-3.jpg?v=2024-09-23T15%3A34%3A50.680Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/1-3.jpg?v=2024-09-23T15%3A34%3A50.680Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/1-3.jpg?v=2024-09-23T15%3A34%3A50.680Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/1-3.jpg?v=2024-09-23T15%3A34%3A50.680Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/1-3.jpg?v=2024-09-23T15%3A34%3A50.680Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/1-3.jpg?v=2024-09-23T15%3A34%3A50.680Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66f139f428b5e30023acb4a0/images/1-3.jpg?v=2024-09-23T15%3A34%3A50.680Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});